/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";
import { Event as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query EventsQuery($slug: [String]) {
    craft {
      entry(section: "events", slug: $slug) {
        title
        url
        slug
        ... on Craft_events_events_Entry {
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          eventDate
          eventEndTime
          eventImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          eventOverview
          eventRegistrationLink
          eventRegistrationCta
          eventFee
        }
      }
    }
  }
`;

const previewQuery = `query EventsQuery($slug: [String]) {
  craft {
    entry(section: "events", slug: $slug) {
      title
      url
      slug
      ... on Craft_events_events_Entry {
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        eventDate
        eventEndTime
        eventImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        eventOverview
        eventRegistrationLink
        eventRegistrationCta
        eventFee
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    eventDate,
    eventEndTime,
    eventImage,
    eventOverview,
    eventRegistrationLink,
    eventRegistrationCta,
    eventFee,
  } = craft.entry;
  return {
    event: {
      title,
      copy: eventOverview,
      date: eventDate?.split("T")[0],
      fee: eventFee,
      link: {
        url: eventRegistrationLink,
        text: eventRegistrationCta,
      },
      meta: {
        title,
        metaTitle,
        metaDescription,
        metaImage: resolveImage(metaImage),
      },
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
